<template>
  <div class="page1">
    <!--主体内容-->
    <div class="content">
      <!--搜索条件区域-->
      <div class="searchWrapper" @keydown.enter.prevent="searchData">
        <el-form
          :inline="true"
          label-position="right"
          :model="formInline"
          class="demo-form-inline"
          label-width="100px"
          ref="form"
        >
          <div class="search_box_title">{{ $t('searchModule.Query_Table') }}</div>
          <div class="col_box">
            <div class="col_left">
              <el-form-item :label="$t('searchModule.full_name')" prop="pdaManagerName">
                <el-input
                  v-model.trim="formInline.pdaManagerName"
                  placeholder="收费员姓名"
                  size="12"
                ></el-input>
              </el-form-item>
              <el-form-item :label="$t('searchModule.PDA_account')" prop="account">
                <el-input
                  v-model.trim="formInline.account"
                  placeholder="PDA账号"
                  size="12"
                ></el-input>
              </el-form-item>
              <el-form-item :label="$t('searchModule.On_duty_status')" prop="pdaManagerState">
                <el-select
                  v-model.trim="formInline.pdaManagerState"
                  filterable
                  placeholder="请选择"
                  size="12"
                >
                  <el-option label="全部" value=""></el-option>
                  <el-option
                    :label="value.name"
                    :value="value.code + ''"
                    v-for="value in allManageState"
                    :key="value.code"
                  ></el-option>
                </el-select>
              </el-form-item>
              <!-- <el-form-item :label="$t('searchModule.sex')"
                        prop="sex">
            <el-select v-model.trim="formInline.sex"
                       size="12">
              <el-option label="全部"
                         value=""></el-option>
              <el-option :label="$t('searchModule.male')"
                         value="1"></el-option>
              <el-option :label="$t('searchModule.female')"
                         value="0"></el-option>
            </el-select>
          </el-form-item>-->
              <!--
          <el-form-item :label="$t('searchModule.role')" prop="pdaManagerRole">
            <el-select v-model.trim="formInline.pdaManagerRole" size="12">
              <el-option label="全部" value></el-option>
              <el-option :label="$t('searchModule.Toll_collector')" value="1"></el-option>
              <el-option label="管理员" value="2"></el-option>
            </el-select>
          </el-form-item> -->

              <el-form-item :label="$t('searchModule.role')" prop="pdaManagerRole">
                <el-select
                  v-model.trim="formInline.pdaManagerRole"
                  filterable
                  size="15"
                >
                  <el-option label="全部" value></el-option>
                  <el-option
                    :label="value.name"
                    :value="value.code"
                    :key="value.code"
                    v-for="value in pdaList"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Road_name')" prop="parkId">
                <el-autocomplete
                  class="inline-input"
                  v-model="modelvalue"
                  :fetch-suggestions="querySearchAsync"
                  placeholder="请输入内容"
                  value-key="parkName"
                  :trigger-on-focus="false"
                  @select="handleSelect"
                ></el-autocomplete>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Merchant_Name')" prop="operationId">
                <el-select
                  v-model.trim="formInline.operationId"
                  filterable
                  size="15"
                  @change="getOperationInfo"
                >
                  <el-option label="全部" value></el-option>
                  <el-option
                    :label="value.operationName"
                    :value="value.operationId"
                    :key="value.operationId"
                    v-for="value in tenantList"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Department_to')" prop="deptId">
                <el-cascader
                  :options="treeData"
                  expand-trigger="click"
                  filterable
                  clearable
                  :show-all-levels="false"
                  v-model="formInline.deptId"
                  :props="defaultProps"
                  change-on-select
                  @change="handleChange"
                ></el-cascader>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Items_received')" prop="assetsId">
                <el-select
                  v-model="formInline.assetsId"
                  filterable
                  placeholder="请选择"
                >
                  <el-option label="全部" value></el-option>
                  <el-option
                    :label="value.assetsName"
                    :value="value.assetsId"
                    v-for="value in assetList"
                    :key="value.assetsId"
                  ></el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="col_right">
              <el-button
                type="primary"
                icon="el-icon-search"
                @click="
                  page = 1;
                  searchData();
                "
                :loading="loading"
                >{{ $t('button.search') }}</el-button
              >
              <el-button icon="el-icon-delete" @click="clearData('form')"
                >{{ $t('button.reset') }}</el-button
              >
            </div>
          </div>
          <div
            class="col_box_boder"
            v-if="$route.meta.authority.button.add"
          ></div>
          <div class="col_box h44" v-if="$route.meta.authority.button.add">
            <div class="col_left">
              <router-link :to="{ path: '/collectorAE' }">
                <el-button
                  type="primary"
                  icon="el-icon-plus"
                  v-if="$route.meta.authority.button.add"
                  >{{ $t('button.addto') }}</el-button
                >
              </router-link>
            </div>
            <div class="col_right mbd4"></div>
          </div>
          <!-- <el-button type="primary" icon='el-icon-upload2' plain  @click="beforeUpload" >批量{{ $t('button.import') }}</el-button> -->
          <!-- <el-button type="primary" plain icon="" @click="exportFile" ><i class='iconfont icon-daochu'></i>{{ $t('button.export') }}</el-button> -->
        </el-form>
      </div>
      <!--列表区域-->
      <div class="tableWrapper bgFFF paddingB10">
        <el-table
          header-cell-class-name="header-call-style"
          v-loading="loading"
          :data="tableData"
          style="width: 100%"
          @select-all="selectAll"
          @select="select"
        >
          <!-- <el-table-column type="selection" width="50"></el-table-column> -->
          <el-table-column
            type="index"
            :label="$t('list.index')"
            width="70"
            align="center"
          ></el-table-column>
          <el-table-column
            show-overflow-tooltip
            :prop="item.prop"
            :label="item.label"
            :width="item.width"
            v-for="item in tableCols"
            align="center"
            :key="item.prop"
            :formatter="item.formatter"
          >
            <template slot-scope="scope">
              <span v-if="item.prop == 'berthCodes'">
                <el-tooltip
                  class="item"
                  effect="dark"
                  v-if="scope.row[item.prop].length > 0"
                  :content="scope.row[item.prop].join(',')"
                  placement="top"
                >
                  <span>{{ scope.row[item.prop].length }}</span>
                </el-tooltip>
                <span v-else>0</span>
              </span>
              <span v-else-if="item.prop === 'pdaManagerState'">
                {{ scope.row[item.prop] == 1 ? "在职" : "离职" }}
              </span>
              <span v-else>{{ scope.row[item.prop] }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="操作"
            align="center"
            width="80"
            v-if="
              $route.meta.authority.button.edit ||
              $route.meta.authority.button.quitAndEntry
            "
          >
            <template slot-scope="scope">
              <el-dropdown @command="handleCommand($event, scope.row)">
                <el-button type="text" size="small" style="padding: 0"
                  >操作 <i class="el-icon-arrow-down"
                /></el-button>
                <el-dropdown-menu slot="dropdown">
                  <!-- <el-dropdown-item command='c'>停车场配置</el-dropdown-item> -->
                  <el-dropdown-item
                    v-if="$route.meta.authority.button.edit"
                    command="a"
                    >编辑</el-dropdown-item
                  >
                  <el-dropdown-item
                    v-show="scope.row.pdaManagerState == 1"
                    v-if="$route.meta.authority.button.quitAndEntry"
                    command="b"
                    >离职</el-dropdown-item
                  >
                  <el-dropdown-item
                    v-show="scope.row.pdaManagerState == 2"
                    v-if="$route.meta.authority.button.quitAndEntry"
                    command="b"
                    >在职</el-dropdown-item
                  >
                  <el-dropdown-item
                    v-if="$route.meta.authority.button.reset"
                    command="c"
                    >重置密码</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
        <!--分页器-->
        <div class="pagerWrapper">
          <div class="block">
            <el-pagination
              @current-change="handleCurrentChange"
              :current-page="page"
              :page-size="pageSize"
              layout="total, prev, pager, next, jumper"
              :total="total"
            ></el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import // dateFormat,
//   "@/common/js/public.js";
export default {
  name: "collectorList",
  data() {
    return {
      treeData: [],
      pdaList: [],
      defaultProps: {
        children: "childNodes",
        label: "deptName",
        value: "deptId",
      },
      temParkData: {},
      modelvalue: "",
      dialogVisible: false,
      fileList: [],
      fileName: "",
      pName: "",
      selection: [],
      index: 0,
      total: 0,
      pageSize: 15,
      page: 1,
      loading: false,
      detailFlag: false,
      areaList: [],
      assetList: [],
      allManageState: [],
      merchantList: [],
      payTypeList: [],
      berthList: [],
      header: {
        "Session-Id": sessionStorage.token,
      },
      tableCols: [
        {
          prop: "pdaManagerName",
          label: this.$t("list.full_name"),
          width: "80",
        },
        // {
        //   prop: "sex",
        //   label: "性别",
        //   width: "70",
        //   formatter: (row) => {
        //     if (row.sex == 0) {
        //       return "女";
        //     } else if (row.sex == 1) {
        //       return "男";
        //     }
        //   }
        // },
        // {
        //   prop: "birthday",
        //   label: "年龄",
        //   width: "70",
        //   formatter: row => {
        //     let bir = row.birthday;
        //     let cur = new Date().getFullYear();
        //     return cur - bir;
        //   }
        // },

        {
          prop: "mobile",
          label: this.$t("list.phone_number"),
          width: "130",
        },
        {
          prop: "account",
          label: this.$t("list.PDA_account"),
          width: "",
        },
        {
          prop: "operationName",
          label: this.$t("list.Merchant_Name"),
          width: "",
        },
        {
          prop: "deptName",
          label: this.$t("list.Department_to"),
          width: "",
        },
        {
          prop: "parkNames",
          label: this.$t("list.Manage_roads"),
          width: "",
        },
        {
          prop: "pdaManagerRoleName",
          label: this.$t("list.role"),
          width: "",
        },
        {
          prop: "berthCodes",
          label: this.$t("list.jurisdictional_berths_number"),
          width: "100",
        },
        {
          prop: "assetsName",
          label: this.$t("list.Items_received"),
          width: "",
        },
        {
          prop: "createdTime",
          label: this.$t("list.Creation_time"),
          width: "180",
        },
        {
          prop: "pdaManagerState",
          label: this.$t("list.On_duty_status"),
          width: "100",
          formatter: (row) => {
            if (row.pdaManagerState == 1) {
              return this.$t("list.On_the_job");
            } else if (row.pdaManagerState == 2) {
              return this.$t("list.quit");
            }
          },
        },
      ],
      tableData: [],
      tenantList: [],
      formInline: {
        pdaManagerName: "",
        pdaManagerState: "",
        sex: "",
        parkId: "",
        assetsId: "",
        account: "",
        pdaManagerRole: "",
        deptId: "",
        operationId: "",
      },
    };
  },
  watch: {
    modelvalue(newVal) {
      if (!newVal) {
        this.formInline.parkId = "";
        this.temParkData = [];
      }
    },
  },
  methods: {
    // 商户名称选择
    getOperationInfo(val) {
      this.formInline.deptId = "";
      this.formInline.operationId = val;
      this.getByCurrentUser(val);
    },
    // 清空
    clearData(formName) {
      this.$refs[formName].resetFields();
      this.modelvalue = "";
    },
    // 获取所属部门
    getByCurrentUser(val) {
      if (val == "") {
        this.treeData = [];
        this.formInline.deptId = "";
        return;
      }
      this.$axios
        .get("/acb/2.0/dept/getDeptTreeByOperationId?operationId=" + val)
        .then((res) => {
          if (res.value) {
            this.treeData = [];
            this.treeData.push(res.value);
            this.treeData = [res.value];
          }
        });
    },
    handleChange(value) {
      this.formInline.deptId = value[value.length - 1];
    },
    getAssetCode() {
      this.$axios
        .get("/acb/2.0/assets/allList", {
          data: {
            status: 1,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            this.assetList = res.value.list;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    /* 商户名称的下拉框 */
    getTenantList() {
      this.$axios.get("/acb/2.0/operation/nameList").then((res) => {
        if (res.state == 0) {
          this.tenantList = res.value;
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },
    // 角色下拉框
    pdachange() {
      this.$axios.get("/acb/2.0/pdaManager/spinner").then((res) => {
        if (res.state == 0) {
          this.pdaList = res.value;
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },
    querySearchAsync(queryString, cb) {
      this.$emit("valueChange", "");
      queryString = queryString.replace(/\s/g, "");
      let url = "/acb/2.0/systems/loginUser/getParkName";
      this.$axios
        .get(url, {
          data: {
            page: 1,
            pageSize: 15,
            dataSource: 1,
            parkName: queryString,
            slaveRelations: "0,1",
          },
        })
        .then((res) => {
          if (res.state == 0) {
            // this.parkData = res.value.list;
            this.temParkData = res.value.list.length
              ? res.value.list[0]
              : { parkName: "", parkId: "" };
            this.formInline.parkId = "";
            cb(res.value.list);
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    handleSelect(item) {
      this.formInline.parkId = item.parkId;
    },
    handleCurrentChange(val) {
      this.page = val;
      this.searchData();
    },
    handleCommand(cmd, data) {
      let obj = { ...data };
      delete obj.parkNames;
      delete obj.parkIds;
      if (cmd == "a") {
        this.$router.push({
          path: "/collectorAE",
          query: obj,
        });
      }
      if (cmd == "b") {
        this.$confirm("此操作将改变在职状态, 是否继续?", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.changeState(data.pdaManagerId, data.pdaManagerState);
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消操作",
            });
          });
      }
      if (cmd == "c") {
        this.$confirm("此操作将重置密码为qwe123, 是否继续?", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.$axios
              .post("/acb/2.0/pdaManager/reset", {
                data: {
                  pdaManagerId: data.pdaManagerId,
                },
              })
              .then((res) => {
                if (res.state == 0) {
                  this.$message({
                    type: "success",
                    message: "密码重置成功",
                  });
                } else {
                  this.$alert(res.desc, this.$t('pop_up.Tips'), {
                    confirmButtonText: this.$t('pop_up.Determine'),
                  });
                }
              });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消重置密码",
            });
          });
      }
    },
    changeState(id, state) {
      state = state == 1 ? "2" : "1";
      let url = "/acb/2.0/pdaManager/updateJobState/" + id + "/" + state;
      this.$axios.post(url).then((res) => {
        if (res.state == 0) {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
          this.searchData();
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },
    // 获取管理员状态
    getAllManageState() {
      let url = "/acb/2.0/pdaManager/jobState/list";
      this.$axios.get(url).then((res) => {
        if (res.state == 0) {
          this.allManageState = res.value;
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },
    select(selection, row) {
      this.selection = selection;
    },
    selectAll(selection) {
      this.selection = selection;
    },
    // 搜索
    searchData() {
      if (!this.formInline.parkId && Object.keys(this.temParkData).length) {
        this.modelvalue = this.temParkData.parkName;
        this.formInline.parkId = this.temParkData.parkId;
        this.temParkData = {};
      }
      let url = "/acb/2.0/pdaManager/query";
      this.$axios
        .get(url, {
          data: {
            page: this.page,
            pageSize: this.pageSize,
            ...this.formInline,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            this.tableData = res.value.list || [];
            this.total = res.value.total * 1;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
  },
  activated() {
    this.searchData();
    this.getAssetCode();
    // this.getByCurrentUser();
  },
  components: {},
  mounted() {
    this.getTenantList();
    this.getAllManageState();
    this.pdachange();
  },
  created() {},
  computed: {},
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
.content {
  overflow: hidden;

  .pagerWrapper {
    text-align: right;
    margin-top: 18px;
    font-size: 12px;

    .export {
      font-size: 12px;
      border: 1px solid #0099FA;
      border-radius: 4px;
      color: #0099FA;
      padding: 8px 13px;
      cursor: pointer;

      .iconfont {
        margin-right: 14px;
      }
    }
  }
}

.detail {
  width: 61.4%;
  height: 82.9%;
  position: absolute;
  top: 8.6%;
  left: 20.1%;
  background: #FFFFFF;
  box-shadow: 0 0 4px 1px rgba(128, 145, 165, 0.3);
  border-radius: 3px;
  z-index: 1000;
  box-sizing: border-box;
  padding: 20px 0;

  .title {
    position: absolute;
    background: #3F4A56;
    border-radius: 3px 3px 0 0;
    color: #fff;
    width: 100%;
    top: 0;
    left: 0;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    padding: 0 12px;
    box-sizing: inherit;
    z-index: 100;

    .el-icon-close {
      float: right;
      margin-top: 14px;
    }
  }

  .info {
    margin-top: 20px;
    padding: 2.1%;
    height: 100%;
    overflow: auto;
    box-sizing: inherit;
  }
}

.mask {
  background: rgba(49, 53, 65, 0.6);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
}

.upload-demo {
  display: inline-block;
}
</style>
